import { useEffect } from "react";

function useScrollLock(displayState) {
  useEffect(() => {
    if (displayState) {
      document.body.classList.add("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }
    return () => {
      document.body.classList.remove("scroll-lock");
    };
  }, [displayState]);
}

export default useScrollLock;

import React from "react";
import Section from "../ui/section";

export default function index() {
  return (
    <div className="bg-darkBlue py-3 px-4 md:px-12 lg:px-[72px]">
      <p className="md:text-sm text-xs text-white/90 !text-center w-full leading-tight">
        © 2024 People’s Courage International. All Rights Reserved
      </p>
    </div>
  );
}

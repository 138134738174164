export const siteSettings = {
  defaultLanguage: "en",
  site_header: {
    menu: [
      {
        id: 1,
        label: "Home",
        path: "/",
      },
      {
        id: 2,
        label: "Who We Are",
        path: "/who-we-are",
        subMenu: [
          {
            id: 1,
            label: "How it Started",
            path: "/who-we-are#how-it-started",
          },
          {
            id: 2,
            label: "Vision, Mission and Aims",
            path: "/who-we-are#vision-mission-and-aims",
          },
          {
            id: 3,
            label: "Our Leadership",
            path: "/who-we-are#our-leadership",
          },
          // {
          //   id: 4,
          //   label: "Our Board",
          //   path: "/who-we-are#our-board",
          // },
        ],
      },
      {
        id: 3,
        label: "What We Do",
        subMenu: [
          {
            id: 1,
            label: "Climate Resilience Initiative (CRI)",
            path: "/climate-resilience-initiative",
          },
          {
            id: 2,
            label: "Migrants Resilience Collaborative (MRC)",
            path: "https://www.migrantresilience.org/",
            external: true,
          },
          {
            id: 3,
            label: "Grassroots Resilience Institute (GRI)",
            path: "/grassroots-resilience-institute",
          },
          {
            id: 4,
            label: "Reports: Trends and Insights",
            path: "/#reports",
          },
        ],
      },
      {
        id: 4,
        label: "Where We Work",
        subMenu: [
          {
            id: 1,
            label: "Bangladesh",
            path: "/bangladesh",
          },
          {
            id: 2,
            label: "India",
            path: "/india",
          },
          {
            id: 3,
            label: "Indonesia",
            path: "/indonesia",
          },
          {
            id: 4,
            label: "Nepal",
            path: "/nepal",
          },
          {
            id: 5,
            label: "Philippines",
            path: "/philippines",
          },
        ],
      },
    ],
  },
};
